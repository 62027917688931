import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";

import ButtonSolid from "../Button/ButtonSolid";
import ButtonGhost from "../Button/ButtonGhost";
import ReviewCardsV2 from "../Reviews/ReviewCardsv2";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { graphql, useStaticQuery } from "gatsby";

const Reviews = ({ headingLevel, otherClasses, showMainButton }) => {
  const data = useStaticQuery(graphql`
    {
      googleIcon: file(relativePath: { eq: "reviews/google-icon.svg" }) {
        publicURL
      }
      heartIcon: file(relativePath: { eq: "reviews/heart-icon.svg" }) {
        publicURL
      }
      purpleFiveStars: file(
        relativePath: { eq: "reviews/purple-five-stars.svg" }
      ) {
        publicURL
      }
    }
  `);

  return (
    <>
      <section
        className={`flex w-full flex-col bg-[#F5F5FD] lg:flex ${otherClasses}`}
      >
        <div className="container flex flex-col items-center">
          {/* <header className="mx-auto mb-14 text-center md:mb-20 md:max-w-[700px]">
            <h1 className="flex flex-col items-center gap-2 text-[40px] lg:flex-row">
              <span>Why Real Estate Agents </span>
              <img
                src={data.heartIcon.publicURL}
                className="w-8 object-contain"
                width={32}
                height={32}
              />{" "}
              <span>Realsynch</span>
            </h1>
            <p>
              Hear from real estate agents like you who discovered Realsynch and
              completely transformed their way of doing business.
            </p>
            <ButtonSolid href="/review-us/" text="Leave a Review" />
          </header> */}
          <div className="flex flex-col gap-5 lg:grid lg:grid-cols-4">
            <ReviewCardsV2 />
          </div>
          {showMainButton && (
            <ButtonSolid
              altStyle={2}
              href="/reviews"
              text="Industry Influencers"
              className="!mx-auto mt-10 w-fit"
            />
          )}
        </div>
      </section>
    </>
  );
};

export default Reviews;

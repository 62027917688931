import React, { useEffect } from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";
import ReviewCards from "../components/Reviews/ReviewCards";
import CallToAction from "../components/Repeating/CTA";
import ReviewsGrid from "../components/Repeating/ReviewsGrid";

const Page = ({ data }) => {
  const useScript = (url) => {
    useEffect(() => {
      const script = document.createElement("script");
      script.src = url;
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }, [url]);
  };

  useScript("https://static.elfsight.com/platform/platform.js");

  return (
    <Layout backgroundColor="#F5F5FD" marginTop="-64px">
      <SearchEngineOptimization
        title="RealSynch Reviews: See What Clients Say"
        description="Read testimonials from real estate professionals who have transformed their business with RealSynch’s automation solutions."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="pt-16 pb-20 md:pb-32 lg:pb-4">
        <div className="container">
          <header className="mx-auto text-center md:mb-4 md:max-w-[640px]">
            <h1>Why Real Estate Agents Love Realsynch</h1>
            <p>
              Read reviews from real estate agents like you who discovered
              Realsynch and completely transformed their way of doing business.
            </p>
            {/* <ButtonSolid href="/review-us/" text="Leave a Review" /> */}
          </header>
          <ReviewsGrid otherClasses="my-16" />

          {/* Elfsight All-in-One Reviews | Real Sync */}
          <script
            src="https://static.elfsight.com/platform/platform.js"
            async
          ></script>
          <div
            class="elfsight-app-0f199173-cb6a-4bcb-a114-aaeaf98a435b"
            data-elfsight-app-lazy
          ></div>
          {/* <div className="mb-16 md:mb-22 md:columns-2 md:gap-x-12">
            <ReviewCards />
          </div> */}
        </div>

        <CallToAction />
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;

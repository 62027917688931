import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { mapEdgesToNodes } from "../../lib/helpers";

const ReviewCardsV2 = () => {
  const data = useStaticQuery(graphql`
    {
      yelp: file(relativePath: { eq: "reviews/Yelp.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 74)
        }
      }
      google: file(relativePath: { eq: "reviews/Google.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 85)
        }
      }
      facebook: file(relativePath: { eq: "reviews/Facebook.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 104)
        }
      }
      linkedin: file(
        relativePath: {
          eq: "0.0 Repeating Modules/Testimonial Slider/Linkedin.svg"
        }
      ) {
        publicURL
      }
      defaultProfilePicture: file(
        relativePath: { eq: "reviews/default-profile-picture.svg" }
      ) {
        publicURL
      }
      quote: file(relativePath: { eq: "reviews/quote.svg" }) {
        publicURL
      }
      back: file(relativePath: { eq: "reviews/back.svg" }) {
        publicURL
      }
      fivestars: file(relativePath: { eq: "reviews/five-stars.svg" }) {
        publicURL
      }
      reviews: allSanityTestimonials(
        filter: { featured: { eq: true } }
        sort: { fields: [order], order: ASC }
      ) {
        edges {
          node {
            name
            profilePicture {
              asset {
                gatsbyImageData(layout: CONSTRAINED, width: 400)
              }
            }
            review
            platform
          }
        }
      }
    }
  `);

  const reviewNodes = (data || {}).reviews ? mapEdgesToNodes(data.reviews) : [];
  const [activeFlippedCard, setActiveFlippedCard] = useState(null);

  const handleClick = (index) => {
    console.log(index);
    setActiveFlippedCard(index);
    console.log(activeFlippedCard);
  };

  return (
    <>
      {reviewNodes.map((review, index) => {
        return (
          <div className={`flip-card`} onClick={() => handleClick(index)}>
            <div
              className={`flip-card-inner ${
                activeFlippedCard == index ? "mobile-flip-card" : ""
              }`}
            >
              <div class="flip-card-front">
                {review.profilePicture && review.profilePicture.asset ? (
                  <div className="relative h-full w-full justify-center rounded-full ">
                    <GatsbyImage
                      image={review.profilePicture.asset.gatsbyImageData}
                      alt={`${review.name} profile headshot`}
                      loading="lazy"
                      className="z-0 h-full w-full"
                    />
                    <div className="bg-white/15 absolute right-4 bottom-4 flex h-10 w-10 items-center justify-center rounded-[50%] font-bold text-white backdrop-blur-md">
                      <img
                        src={data.quote.publicURL}
                        width="100%"
                        className="h-5 w-5"
                        alt="Default profile headshot"
                      />
                    </div>
                  </div>
                ) : (
                  <img
                    src={data.defaultProfilePicture.publicURL}
                    width="100%"
                    className="w-full"
                    alt="Default profile headshot"
                  />
                )}
              </div>
              <div className="flip-card-back relative">
                <div className="flex h-full w-full flex-col items-start justify-between">
                  <blockquote>
                    <q className="mb-0 text-left font-normal text-[#666] before:hidden">
                      {review.review}
                    </q>
                  </blockquote>
                  <div className="bottom-0 top-auto justify-self-end font-bold text-primary-900">
                    {review.name}
                  </div>
                </div>
                <div className="bg-white/15 absolute right-4 bottom-4 flex h-10 w-10 items-center justify-center rounded-[50%] border-[1px] border-[#E6E6E6] font-bold text-white backdrop-blur-md">
                  <img
                    src={data.back.publicURL}
                    width="100%"
                    className="h-5 w-5"
                    alt="Default profile headshot"
                  />
                </div>
                {/* {review.platform === "Google" && (
                  <GatsbyImage
                    alt={`Google`}
                    image={data.google.childImageSharp.gatsbyImageData}
                    loading="lazy"
                  />
                )}

                {review.platform === "Yelp" && (
                  <GatsbyImage
                    alt={`Yelp`}
                    image={data.yelp.childImageSharp.gatsbyImageData}
                    loading="lazy"
                  />
                )}

                {review.platform === "Facebook" && (
                  <GatsbyImage
                    alt={`Facebook`}
                    image={data.facebook.childImageSharp.gatsbyImageData}
                    loading="lazy"
                  />
                )}

                {review.platform === "LinkedIn" && (
                  <img
                    alt={`LinkedIn`}
                    src={data.linkedin.publicURL}
                    width={100}
                  />
                )} */}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ReviewCardsV2;
